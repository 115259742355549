import Vue from 'vue'
import App from './App.vue'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'
import router from './router'
import VueToast from 'vue-toast-notification'
import moment from 'vue-moment'
import VueSocialSharing from 'vue-social-sharing'
import 'vue-toast-notification/dist/theme-default.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/fonts/Gordita/Gordita-Webfont.css'
import './assets/fonts/FontAwesome/FontAwesome.min.css'
import './assets/style/app.css'
import 'vue2-animate/dist/vue2-animate.min.css'

let app

const firebaseConfig = {
	apiKey: "AIzaSyCEkFfj8tnnglM4lWWc40B3TRJ0nPdQwy0",
	authDomain: "uxfacts.firebaseapp.com",
	databaseURL: "https://uxfacts.firebaseio.com",
	projectId: "uxfacts",
	storageBucket: "uxfacts.appspot.com",
	messagingSenderId: "982186325165",
	appId: "1:982186325165:web:56ed6d769f7b7b96550224",
	measurementId: "G-S8234R1HEB"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)
firebase.analytics()

Vue.prototype.$db = firebaseApp.firestore()
Vue.prototype.$analytics = firebase.analytics();

Vue.use(BootstrapVue)
Vue.use(moment)
Vue.use(VueSocialSharing)
Vue.use(VueToast, {
	position: 'top'
})

firebase.auth().onAuthStateChanged(() => {
	if (!app) {
		app = new Vue({
			store,
			router,
			render: h => h(App)
		}).$mount('#app')
	}
});