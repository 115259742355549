<template>
  <div>
    <h1 class="text-center" v-if="!loaded">Loading...</h1>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
	name: 'App',
	data () {
		return {
			loaded: false
		}
	},
	components: {},
	mounted () {
		let currentUser = firebase.auth().currentUser
		if (currentUser) {
			this.$store.dispatch('Admin/setUser', currentUser.uid)
		}

		this.$store.dispatch('Questions/loadQuestions').then(() => {
			this.loaded = true
		})
	}
}
</script>

