import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = new Router({
	mode: 'history',
	duplicateNavigationPolicy: 'ignore',
	routes: [
		{
			path: '*',
			redirect: '/'
		},
		{
			path: '/',
			name: 'questions',
			component: () => import('../components/questions.vue')
		},
		{
			path: '/admin',
			name: 'admin',
			component: () => import('../components/admin.vue')
		}
	]
})

routes.beforeEach((to, from, next) => {
	next()
})

export default routes
