import Vue from 'vue'

export default {
	namespaced: true,

	state: {
		questions: [],
		score: 0,
		gameOver: false,
		questionQueue: []
	},

	mutations: {
		setScore (state, score) {
			state.score = score
		},
		increaseScore (state) {
			state.score++
		},
		setGameOver (state, gameOver) {
			state.gameOver = gameOver
		},
		setQuestions (state, questions) {
			state.questions = questions
		},
		setQuestionQueue (state, questionQueue) {
			state.questionQueue = questionQueue
		},
		addQuestionToQueue (state, question) {
			state.questionQueue.push(question)
		},
		addQuestion (state, question) {
			state.questions.push(question)
		},
		modifyQuestion (state, question) {
			let modifyIdx = state.questions.findIndex((q) => {
				return q.id === question.id
			})
			if (modifyIdx >= 0) {
				Object.assign(state.questions[modifyIdx], question)
			}
		},
		removeQuestion (state, question) {
			let modifyIdx = state.questions.findIndex((q) => {
				return q.id === question.id
			})
			if (modifyIdx >= 0) {
				state.questions.splice(modifyIdx, 1)
			}
		}
	},

	actions: {
		loadQuestions ({commit}) {
			commit('setQuestions', [])
			return Vue.prototype.$db.collection('questions').get().then((snapshot) => {
				snapshot.forEach(function (docRef) {
					let data = docRef.data()
					data.id = docRef.id
					commit('addQuestion', data)
				})
			})
		},
		saveQuestion ({commit}, question) {
			return Vue.prototype.$db.collection('questions').add(question).then((doc) => {
				let data = question
				data.id = doc.id
				return commit('addQuestion', data)
			})
		},
		updateQuestion ({commit}, question) {
			let data = Object.assign({}, question)
			delete data.id
			return Vue.prototype.$db.collection('questions').doc(question.id).set(data).then(() => {
				return commit('modifyQuestion', question)
			})
		},
		deleteQuestion ({commit}, question) {
			return Vue.prototype.$db.collection('questions').doc(question.id).delete().then(() => {
				return commit('removeQuestion', question)
			})
		}
	},

	getters: {
		questions: state => {
			return state.questions
		}
	}
}
