import Vue from 'vue'
import Vuex from 'vuex'
import Questions from './modules/questions'
import Admin from './modules/admin'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Questions,
    Admin
  },
  strict: debug
})
