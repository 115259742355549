import firebase from 'firebase/app'
import 'firebase/auth'

export default {
	namespaced: true,

	state: {
		user: null
	},

	mutations: {
		setUser (state, user) {
			state.user = user
		}
	},

	actions: {
		login ({commit}, data) {
			firebase.auth().signInWithEmailAndPassword(data.email, data.password).then((userCredential) => {
				commit('setUser', userCredential.user.uid)
			}).catch((e) => {
				alert(e.message)
			})
		},
		logout ({commit}) {
			firebase.auth().signOut().then(() => {
				commit('setUser', null)
			}).catch((e) => {
				alert(e.message)
			})
		},
		setUser ({commit}, uid) {
			commit('setUser', uid)
		}
	},

	getters: {}
}
